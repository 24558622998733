import { FC, useState } from "react";
import {
  Box,
  Checkbox,
  Collapse,
  FormControlLabel,
  FormGroup,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  useTheme,
} from "@mui/material";
import {
  Check,
  Close,
  Edit,
  ExpandLess,
  ExpandMore,
} from "@mui/icons-material";

import { IProduct } from "../../../types/products";
import useProduct from "../../../services/useProduct";

interface Form {
  cost: string;
  price: string;
  stock: string;
  unlimited_stock: boolean;
}

interface Props {
  product: IProduct;
}

const Product: FC<Props> = ({ product }) => {
  const theme = useTheme();
  const [editable, setEditable] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [form, setForm] = useState<Form | null>({
    cost: product.cost.toString(),
    price: product.price.toString(),
    stock: product.stock.toString(),
    unlimited_stock: product.unlimited_stock,
  });
  const { mutate } = useProduct();

  let price, cost, stock, unlimited_stock;
  if (editable && form !== null) {
    price = form.price;
    cost = form.cost;
    stock = form.stock;
    unlimited_stock = form.unlimited_stock;
  } else if (!editable && product !== null) {
    price = product.price.toString();
    cost = product.cost.toString();
    stock = product.stock.toString();
    unlimited_stock = product.unlimited_stock;
  } else {
    return null;
  }

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const toggleEditable = () => {
    if (!editable) {
      setForm({
        cost: product.cost.toString(),
        price: product.price.toString(),
        stock: product.stock.toString(),
        unlimited_stock: product.unlimited_stock,
      });
    }

    setEditable(!editable);
  };

  const handleFormChange = (formKey: any) => (e: any) => {
    if (form) {
      let value;
      if (formKey === "unlimited_stock") {
        value = e.target.checked;
      } else {
        value = e.target.value;
      }
      setForm({
        ...form,
        [formKey]: value,
      });
    }
  };

  const submitForm = () => {
    if (form) {
      mutate(
        {
          productId: product.id,
          product: {
            cost: parseFloat(form.cost) || 0,
            price: parseFloat(form.price) || 0,
            stock: parseFloat(form.stock) || 0,
            unlimited_stock: form.unlimited_stock,
          },
        },
        {
          onSuccess: () => {
            setEditable(false);
            setForm(null);
          },
        },
      );
    }
  };

  const isPriceLessThanCost =
    !Number.isNaN(parseFloat(price)) &&
    !Number.isNaN(parseFloat(cost)) &&
    parseFloat(price) < parseFloat(cost);
  const isValid =
    !isPriceLessThanCost && price !== "" && cost !== "" && stock !== "";

  return (
    <div>
      <ListItem disablePadding onClick={handleToggle}>
        <ListItemButton>
          <ListItemText
            primary={`${product.base_product.name} - ${product.producer.name}`}
          />
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItem sx={{ justifyContent: "flex-end", pl: 4 }}>
            {editable ? (
              <Box sx={{ display: "flex", gap: "0.5rem" }}>
                <IconButton disabled={!isValid} onClick={submitForm}>
                  <Check color="success" />
                </IconButton>
                <IconButton onClick={toggleEditable}>
                  <Close />
                </IconButton>
              </Box>
            ) : (
              <IconButton onClick={toggleEditable}>
                <Edit />
              </IconButton>
            )}
          </ListItem>
          {isPriceLessThanCost ? (
            <ListItem>
              <Box sx={{ color: theme.palette.error.main }}>
                Custo deve ser menor ou igual ao preço
              </Box>
            </ListItem>
          ) : null}
          <ListItem sx={{ pl: 4 }}>
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                gap: "1rem",
                justifyContent: "space-between",
              }}
            >
              <TextField
                disabled={!editable}
                error={isPriceLessThanCost || !cost}
                label="Custo"
                onChange={handleFormChange("cost")}
                required
                value={cost}
                variant="outlined"
              />
              <TextField
                disabled={!editable}
                error={isPriceLessThanCost || !price}
                label="Preço"
                onChange={handleFormChange("price")}
                required
                value={price}
                variant="outlined"
              />
              <TextField
                disabled={!editable}
                error={!stock}
                label="Estoque"
                onChange={handleFormChange("stock")}
                required
                value={stock}
                variant="outlined"
              />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={unlimited_stock}
                      disabled={!editable}
                      onChange={handleFormChange("unlimited_stock")}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 36 } }}
                    />
                  }
                  label="Ilimitado"
                />
              </FormGroup>
            </Box>
          </ListItem>
        </List>
      </Collapse>
    </div>
  );
};

export default Product;

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import {
  Checkbox,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { FC, useState } from "react";
import { IOrder } from "../../../types/orders";
import OrderItem from "../OrderItem";
import { sortBy } from "lodash";
import StatusIcon, { STATUSES } from "../../../components/StatusIcon";
import { getOrderStatus, getTotal } from "../utils";
import useUpdateOrderStatus from "../../../services/useUpdateOrderStatus";
import AddItem from "../AddItem";

interface Props {
  checked: boolean;
  fairId: number;
  forceExpand: boolean;
  order: IOrder;
  toggle: () => void;
}

const Order: FC<Props> = ({ fairId, forceExpand, order, checked, toggle }) => {
  const [expanded, setExpanded] = useState(false);
  const { mutate } = useUpdateOrderStatus();

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const setStatus = (status: keyof typeof STATUSES) => {
    mutate({ orderId: order.id, status });
  };

  const total = getTotal(order);
  const status = getOrderStatus(order.items);

  let allowedStatuses: (keyof typeof STATUSES)[] = [];
  if (status === "ORDERED" || status === "FINISHED") {
    allowedStatuses = ["PAID"];
  } else if (status === "PAID") {
    allowedStatuses = ["ORDERED", "FINISHED"];
  }

  return (
    <>
      <ListItem disablePadding>
        <Checkbox checked={checked} onChange={toggle} />
        <StatusIcon
          setStatus={setStatus}
          status={status}
          statuses={allowedStatuses}
        />
        <AddItem orderId={order.id} />
        <ListItemButton onClick={handleToggle}>
          <Typography sx={{ flex: "1 1 auto" }}>
            {order.urban_partner.name}
          </Typography>
          {expanded ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
      </ListItem>
      <Collapse in={expanded || forceExpand}>
        <List component="div" disablePadding>
          <ListItem>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Status</TableCell>
                    <TableCell align="right">Qtd.</TableCell>
                    <TableCell>Produto</TableCell>
                    <TableCell align="right">Valor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortBy(order.items, "product.base_product.name").map(
                    (item) => (
                      <OrderItem fairId={fairId} key={item.id} item={item} />
                    ),
                  )}
                  {parseFloat(order.delivery_fee) > 0 &&
                  status !== "CANCELED" ? (
                    <TableRow>
                      <TableCell align="right" colSpan={3}>
                        Frete
                      </TableCell>
                      <TableCell align="right">{order.delivery_fee}</TableCell>
                    </TableRow>
                  ) : null}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TableCell align="right" colSpan={3}>
                      Total
                    </TableCell>
                    <TableCell align="right">{total.toFixed(2)}</TableCell>
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </ListItem>
        </List>
      </Collapse>
    </>
  );
};

export default Order;
